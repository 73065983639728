// Background colors
$primary-color: #4294a0;
$primary-color_hover: #2896a5;
$secondary-color: #122140;
$secondary-color_hover: #4d667d;
$danger-color: #e55353;
$danger-color_hover: #e03232;
$warning-color: #fdc122;
$warning-color_hover: #fdcd4e;
$success-color: #fee7a9;
$success-color_hover: #feebba;
$info-color: #fff9e8;
$info-color_hover: #fffaec;
$disabled-color: #ababab;
$newBlue-color: #259bd9;
$newGold-color: #fdc121;
$dark-blue: #213f5d;
$new-red-color: #e67567;
$new-background-color: #fff9e7;
$purple-background: #f1f2fd;
$new-purple-bg: #6f80ea;

// change theme
$theme-colors: (
  // "primary": #4faab6, changed this color to color below
  "primary": #4294a0
);

// Text colors
$main-text-color: #111111;
$second-text-color: #30333a;
$second-text-color_hover: #595b61;
$gray-text-color: #ababab;
$semigray-text-color: #e3e3e3;
$lightgray-text-color: #f7f7f7;
$meta-text-color: #4b4b4b;
$gold-text-color: #fdc122;
$subtitle_details_page_color: #21405d;

// Other variables
$color_main_text: #282f40;
$color-white: #ffffff;
$color_second_text: #616161;
$title-color: #392f5a;
$input-border-color: #ababab;

$text_name_user-color: #313131;
$title_footer-color: #030303;
$text_footer-color: #6a7180;

$main_background: #e6e6e6;

// @media variables
$breack_point_lg: 1024px;
$breack_point_md: 768px;
$breack_point_sm: 580px;
$breack_point_xs: 480px;
$breack_point_xxs: 380px;
$max_width: 1100px;

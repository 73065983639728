// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}

a {
  &:hover {
    text-decoration: none;
  }
}

.c-body {
  max-height: calc(100vh - 57px);
  overflow: auto;
  // overflow: hidden;
}

.c-main {
  padding-top: 10px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
}

@import "./_variables.scss";
@import "./mixins.scss";

.view_icon {
  fill: #e7c000;
  width: 16px;

  &:hover {
    cursor: pointer;
  }
}

.link-hover {
  display: block;
  transition: 0.7s;

  &:hover {
    transform: scale(1.05);
    transition: 0.7s;
    box-shadow: 5px 5px 5px #ccc;
  }
}

.width_60 {
  max-width: 60px;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-align: end;
}

.width_95 {
  max-width: 95px;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-align: end;

  &.is_comments {
    border: 2px solid $danger-color;
  }
}

.width_125 {
  max-width: 125px;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-align: end;

  &.is_comments {
    border: 2px solid $danger-color;
  }
}

span.value-input {
  padding-right: 5px;
}

input.width_125,
span.width_125,
input.width_60,
span.width_60 {
  height: 23px;
  padding-right: 5px;
}

span.width_125 {
  min-width: 125px;
}

.width_95_span {
  width: 95px;
  text-align: end;
  display: block;
}

.width_125_span {
  width: 125px;
  text-align: end;
  display: block;
}

.first_params {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.mh-100px {
  max-height: 100px;
}

.upload_file {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: $primary-color;
  border: 1px solid $primary-color;
  // padding: 0.375rem 0.75rem;
  // font-size: 0.875rem;
  // line-height: 1.5;
  // border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: $primary-color_hover;
    border-color: $primary-color_hover;
  }

  &_disabled {
    cursor: inherit;
  }
}

.bold-row-table,
.bold_row_table {
  font-weight: bold;

  input {
    font-weight: inherit;
    color: inherit;
  }
}

.table {
  th svg {
    right: inherit;
  }
}

.modalFormula {
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 5px;
}

// color sources in prognosis table
.manual_color,
.duetto_color,
.timeplan_color,
.formula_color {
  input {
    font-weight: inherit;
    color: inherit;
  }
}

.duetto_color {
  color: $primary-color;
}

.timeplan_color {
  color: #f9b115;
}

.error {
  color: $danger-color;
}

.selector {
  margin-bottom: 1rem;

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }
}

.wrap_percent {
  position: relative;

  .percent {
    position: absolute;
    right: 20px;
    padding-right: 5px;

    &_show {
      position: absolute;
      right: 4px;
    }
  }

  .paddingRight17 {
    padding-right: 17px;
  }
}

.width_100 {
  max-width: 100px;
  border: 1px solid #ccc;
  border-radius: 3px;

  &.is_comments {
    border: 2px solid $danger-color;
  }
}

// table styles
.wrap_table {
  .wrap_loader_table {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(235, 237, 239, 0.5);
    position: fixed;
    top: 0;
    left: 0;

    &.table_empty {
      position: absolute;
    }
  }

  .show_tab_details {
    display: flex;
    align-items: center;
    font-weight: bold;
    padding-left: 5px;
    cursor: pointer;

    img {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }
}

.forecast_table {
  padding: 0rem 1.25rem 0.5rem;
  max-height: 100%;
  overflow: scroll;

  // scroll styles
  ::-webkit-scrollbar {
    height: 15px;
    &:hover {
      height: 15px;
    }
  }

  ::-webkit-scrollbar-thumb {
    background: $primary-color;
    border-radius: 5px;
    transition: 0.15s ease-in-out;
    width: 15px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $primary-color_hover;
    transition: 0.15s ease-in-out;
  }

  tr {
    td,
    th {
      min-width: 170px;
    }
    td:first-child,
    th:first-child {
      min-width: 200px;
      flex-wrap: wrap;
    }
  }

  tbody {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;

    tr,
    td {
      width: 100%;
      display: flex;

      &:focus {
        outline: none !important;
      }
    }

    td[colspan="0"] {
      width: 100%;
    }
    // scroll styles
    &::-webkit-scrollbar {
      width: 15px;
      height: 15px;
    }

    &::-webkit-scrollbar-thumb {
      background: $primary-color;
      border-radius: 15px;
      transition: 0.15s ease-in-out;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $primary-color_hover;
      transition: 0.15s ease-in-out;
    }
  }

  thead {
    display: inline-block;
    table-layout: fixed;
    tr,
    th {
      width: 100%;
      display: flex;
    }
  }

  .bgLight {
    background-color: #f3f3f3;

    input {
      background-color: #f3f3f3;
    }
  }
}

.wrap_table_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;

  h1 {
    margin-bottom: 0;
  }
}

// notifications styles
.toaster {
  word-break: break-all;
}

.notification {
  background: #fff;
  box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  width: 320px;
  min-width: 250px;
  height: 50px;
  position: fixed;
  top: 50px;
  right: -500px;
  z-index: 999;
  opacity: 0;
  transition: all 0.5s;

  &.success {
    background-color: #62ab62;
  }

  &.warning {
    background-color: $warning-color;
  }

  &.error {
    background-color: $danger-color;
  }

  &.active {
    right: 50px;
    opacity: 1;
    transition: all 0.5s;
  }
}

.react-select-container.is-invalid .react-select__control {
  border-color: rgb(229, 83, 83);
  //box-shadow: 0 0 0 0.2rem rgb(229, 83, 83);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: hsl(0, 0%, 95%);
}

// additional styles
.forecast-details {
  main {
    height: 100%;
    & > div,
    & > div > div {
      height: 100%;
    }
    & > div > div {
      .card {
        height: 94%;
        width: inherit;
        header {
          position: absolute;
          left: 0;
          right: 0;
          width: 100%;
          z-index: 2;
        }
        .forecast_table {
          height: inherit;
          // padding-top: 81px;
          padding-top: 40px;
          overflow: hidden;
          padding-bottom: 0px;
          & > div.table-responsive {
            height: 100%;
            overflow: hidden;
            padding-bottom: 2px;
          }
          & > div > div {
            position: absolute;
            top: 50px;
            width: 100%;
            z-index: 3;
          }
          &.with-alert {
            & > div.table-responsive {
              height: 91%;
            }
          }
          table {
            display: block;
            height: 90%;
            position: relative;
            overflow: auto;
            margin: 50px 0 0 0;
            thead {
              top: 0;
              position: sticky;
              z-index: 2;
              background-color: #fff;
              //width: max-content;
            }
          }

          .alert {
            z-index: 2;
            cursor: pointer;
            top: 60px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .fullViewAlert {
            z-index: 2;
            cursor: pointer;
            top: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .max_table_view {
          padding-top: 0;
          table {
            margin-top: 0;
            height: 100%;
          }
        }
      }
      .max_card_view {
        height: 100%;
      }
      .oldSafari.card {
        .forecast_table {
          table {
            padding-top: 98px;
          }
          table,
          table tbody {
            max-height: -webkit-fill-available;
          }
          table thead {
            top: 50px;
            width: 100%;
            position: absolute;
            overflow-x: scroll;
            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }
    }
  }
}

//settings
.settings-cell {
  width: 60px;
}

// @media
@media (max-width: $breack_point_lg) {
  .header_table {
    padding-top: 0px !important;
  }

  .wrap_table_header {
    padding: 0.5rem 0.75rem;

    h1 {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }

  .container-fluid {
    padding: 0 10px !important;
  }
}

@media (max-width: $breack_point_md) {
  .forecast-details {
    main > div > div {
      overflow-x: hidden;
      .card {
        .forecast_table {
          // padding-top: 125px;
          padding-top: 60px;
          & > div > div {
            width: inherit;
          }
          .c-datatable-filter {
            svg {
              display: none;
            }
          }
        }
      }
    }
    .header_table {
      position: relative;
    }
  }
  html:not([dir="rtl"]) {
    footer {
      @include flexRow;
      @include flexCenter;
      .mfs-auto {
        margin-left: 0 !important;
      }
    }
  }
  .c-datatable-filter {
    svg {
      display: none;
    }
  }
}

@media (max-width: $breack_point_sm) {
  .header_table {
    flex-direction: column;
    button,
    label {
      width: 100%;
    }
  }
}

@media (max-width: $breack_point_xs) {
  .breadcrumb,
  .profile-menu {
    min-height: 35px;
    padding-left: 0 !important;
    padding-right: 5px !important;
    .nav-link {
      padding-top: 0;
    }
  }
  .breadcrumb {
    padding-left: 15px !important;
  }
}

.wrap_search_select {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;

  & > div {
    // top: 88px !important;
    top: 45px !important;
  }

  .wrap_inner {
    display: flex;
    align-items: center;
    margin-top: 6px;
  }

  &_label {
    margin-right: 5px;
  }

  .react-select-container {
    min-width: 300px;
  }

  .form-group {
    margin-bottom: 0;
  }

  @media (max-width: $breack_point_lg) {
    .wrap_inner {
      margin-top: 0;
    }
  }

  @media (max-width: $breack_point_md) {
    .wrap_inner {
      flex-direction: column;
      align-items: flex-start;
    }

    // & > div {
    //   top: 110px !important;
    // }
  }

  @media (max-width: $breack_point_xxs) {
    .react-select-container {
      min-width: 280px;
    }
  }
}

.alert {
  //position: fixed;
  //z-index: 9999;
}

.hotelSettings {
  display: flex;
  margin: 50px -15px 0;

  @media (max-width: $breack_point_md) {
    flex-direction: column;
  }
}

.forecast-value-comment-button {
  cursor: pointer;
  transform: scaleX(-1);
  margin-left: 5px;
  margin-top: -2px;
}

.forecast-value-comment-button:focus {
  outline: none;
}

.has-comments .forecast-value-comment-button {
  color: #3399ff !important;
}

.has-comments span,
.has-comments input,
.has-comments span:focus-visible,
.has-comments input:focus-visible {
  border-color: #3399ff !important;
  color: #3399ff !important;
  outline-color: #3399ff !important;
}

.has-comments {
  .comment-count {
    background-color: #3399ff;
  }
}

.current-comment span,
.current-comment span:focus-visible,
.current-comment input:focus-visible {
  border-color: #e55353 !important;
  color: #e55353 !important;
  outline-color: #e55353 !important;
}

.current-comment .forecast-value-comment-button {
  color: #e55353 !important;
}

.current-comment {
  .comment-count {
    background-color: #e55353;
  }
}

.value-comments-icon {
  position: relative;
  .comment-count {
    padding: 1px 3px;
    position: absolute;
    right: -3px;
    top: -2px;
    color: #fff !important;
    font-size: 9px;
  }
}

.comments-history .comment-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  .date {
    width: 10%;
  }
  .user {
    padding: 0 10px;
    word-break: break-word;
    width: 30%;
  }
  .text {
    width: 60%;
  }
}

.forecast-comments .comment-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  .date {
    width: 20%;
  }
  .user {
    width: 20%;
  }
  .text {
    width: 60%;
  }
}

.forecast-comments {
  .table {
    th {
      padding: 8px 10px;
    }
    td {
      padding: 5px 10px;
    }
  }
}

.wrap_table_header .header-buttons {
  min-width: fit-content;
  a {
    margin-left: 15px;
    .comments-icon {
      position: relative;
      .comment-count {
        position: absolute;
        right: -9px;
        top: -6px;
      }
    }
  }
}

button:focus,
a svg:focus {
  outline: none;
}

.notification-item {
  display: flex;
  justify-content: space-between;
}

td svg {
  cursor: pointer;
}

.loadingTable {
  tbody {
    tr {
      td {
        border-top-color: transparent;
      }
    }
  }
}

.stickWrapper {
  table {
    thead {
      tr {
        th {
          z-index: 1;
          background-color: #fff;
        }
        th:first-child {
          background-color: #fff;
          left: 0;
          top: 0;
          z-index: 2;
          position: sticky;
          -webkit-overflow-scrolling: touch;
        }
      }
    }
  }

  tr {
    td:first-child,
    th:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
    }
  }

  .stick {
    position: sticky;
    left: 0;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
  }

  > tbody {
    overscroll-behavior: contain;
  }

  @media (max-width: $breack_point_lg) {
    // .stick {
    // flex: 1 0 calc(50% + 7px);
    // }
  }
}

@media (max-width: $breack_point_xs) {
  .notification-item {
    display: block;
  }
}

@media (max-width: $breack_point_xxs) {
  .wrap_table_header h1 {
    font-size: 0.8rem;
  }
}

th.collapsable,
td.collapsable {
  display: table-cell;
}

@media only screen and (max-width: 576px) {
  //show cols here
  th.collapsable,
  td.collapsable {
    display: none !important;
  }

  .wrap_search_select .wrap_inner {
    margin-top: 3px;
  }
}

td svg {
  cursor: pointer;
}
